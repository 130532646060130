@import '../../styles/helpers/index.scss';

.cta {
  padding: 100px 0;
  position: relative;

  background-image: url('../../static/cta-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 0;

  &::before {
    content: '';

    background-image: url('../../static/warranty.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    width: 200px;
    height: 200px;

    position: absolute;
    right: 10%;
    top: -100px;

    @media screen and (max-width: $tablet-sm) {
      width: 100px;
      height: 100px;

      top: 5%;
      right: 5%;
    }
  }

  .section-title h2 {
    color: #fff !important;
  }

  &__links {
    margin: 50px 0 0;
    @include align-center;
    gap: 15px;

    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      gap: 10px;
    }

    & > span {
      font-family: 'Inter';
      font-weight: 600;
      font-size: 18px;
      line-height: 200%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
      }

      & > a {
        color: #fff;
        position: relative;

        &::before {
          content: '';
          width: 0;
          height: 2px;
          background-color: #fff;

          transition: width 0.3s ease;

          position: absolute;
          bottom: 0;
        }

        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
    }
  }
}
