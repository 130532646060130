@import './helpers/variables.scss';
@import './helpers/mixins.scss';

html {
  height: 100% !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Inter', sans-serif;

  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  main {
    flex: 1 0 auto;
    padding: 101px 0 0;
    transition: all 0.3s ease;

    @media screen and (max-width: $tablet-sm) {
      padding: 81px 0 0;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }
}

.dashed {
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    width: calc(100% + 10px);
    height: 100%;

    position: absolute;
    top: 0;
    left: -5px;

    border: 1px dashed map-get($colors, 'red');
    border-radius: 10px;

    transform: rotate(-0.06deg);
  }
}

.legal-content,
.page-not-found,
.thank-you {
  padding: 120px 0;

  @media screen and (max-width: $tablet-sm) {
    padding: 60px 0;
  }
}

.legal-content {
  font-family: 'Inter';
  font-size: 18px;
  line-height: 150%;
  color: rgba(#000, 0.5);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    color: #000;
  }

  p {
    &:not(:last-of-type) {
      margin: 0 0 15px;
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  ul {
    margin: 0 0 15px;
    list-style-type: disc;
    padding: 0 0 0 30px;
  }
}

.page-not-found,
.thank-you {
  text-align: center;

  img {
    margin: 0 auto;
  }

  h2 {
    margin: 65px 0 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    color: map-get($colors, 'darkgrey');
  }

  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: map-get($colors, 'text');
  }

  a {
    display: block;
    margin: 15px 0 0;
    font-family: 'Montserrat';
    font-size: 24px;
    line-height: 1.24;
    color: map-get($colors, 'darkgrey');
    &:hover {
      text-decoration: underline !important;
    }
  }
}

.book-now {
  margin: 120px 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0;
  }

  .section-title {
    text-align: center;
    margin: 0 0 30px;
  }

  .container {
    & > div:last-of-type {
      max-width: 600px;
      margin: 0 auto;
    }
  }
}
