@import '../../styles/helpers/index.scss';

.contact-us {
  margin: 120px 0 0;
  background-color: #f2f2f2;
  padding: 100px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .container {
    display: flex;
    gap: 50px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;
      width: 100%;
      padding-bottom: 85px;

      &:last-of-type {
        @media screen and (max-width: $tablet-sm) {
          padding: 0 0 50px;
        }
      }

      @media screen and (max-width: $tablet-sm) {
        max-width: 100%;
        padding-bottom: 0;
      }
    }
  }

  .content {
    background-image: url('../../static/contact-us-bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

    @media screen and (max-width: $tablet-sm) {
      background-image: none;
    }

    p {
      max-width: 350px;
      margin: 30px 0 0;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: map-get($colors, 'darkgrey');

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }
    }
  }

  .section-title {
    max-width: 450px;
  }
}
