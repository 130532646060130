@import '../../styles/helpers/index.scss';

.advantages {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .section-title {
    max-width: 580px;
  }

  &__grid {
    margin: 45px 0 0;
    @include grid($rows: repeat(2, 1fr), $columns: repeat(3, 1fr));

    @media screen and (max-width: $tablet-sm) {
      grid-template-rows: 1fr;
    }

    & > div:last-of-type {
      position: relative;
      grid-area: 2 / 2 / 3 / 4;

      background: #f2f2f2;
      border: 1px dashed #373333;

      @include align-center;
      justify-content: center;

      @media screen and (max-width: $tablet-sm) {
        grid-area: unset;
      }

      &::before {
        content: '';

        background-image: url('../../static/scissors.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        width: 20px;
        height: 30px;

        position: absolute;

        right: -12px;
        bottom: 50px;
        z-index: 2;
      }

      & > div {
        background-color: #fff;

        background-image: url('../../static/coupon-bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        overflow: hidden;

        height: calc(100% - 20px);
        width: calc(100% - 20px);

        @include align-center;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width: $tablet-sm) {
          padding: 20px 0;
          background-image: none;
        }

        header {
          @include align-center;
          gap: 15px;
          color: map-get($colors, 'red');
          font-family: 'Montserrat', sans-serif;

          @media screen and (max-width: $tablet-sm) {
            flex-direction: column;
            text-align: center;
            gap: 5px;
          }

          & > span {
            font-weight: 800;
            font-size: 90px;
            line-height: 120%;
            text-transform: uppercase;

            @media screen and (max-width: $tablet-sm) {
              font-size: 60px;
            }
          }

          & > div {
            span {
              display: block;

              &:first-of-type {
                font-weight: 600;
                font-size: 36px;
                line-height: 150%;

                @media screen and (max-width: $tablet-sm) {
                  font-size: 24px;
                }
              }

              &:last-of-type {
                font-size: 22px;
                line-height: 125%;
                color: map-get($colors, 'darkgrey');

                @media screen and (max-width: $tablet-sm) {
                  font-size: 16px;
                }
              }
            }
          }
        }

        .btn {
          margin: 35px 0;

          @media screen and (max-width: $tablet-sm) {
            margin: 20px 0;
          }
        }

        .phone {
          position: relative;
          font-family: 'Montserrat';
          font-weight: 600;
          font-size: 26px;
          line-height: 32px;
          color: #070707;
          text-indent: 45px;

          &::before {
            content: '';

            background-image: url('../../static/phone-red.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            width: 30px;
            height: 30px;

            position: absolute;

            top: 50%;
            left: 0;

            transform: translateY(-50%);
          }
        }
      }
    }

    &--card {
      padding: 40px 20px;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
      border-radius: 10px;

      @include align-center;
      flex-direction: column;
      justify-content: center;

      @include scale;

      &:hover {
        @media screen and (max-width: $tablet-sm) {
          transform: none !important;
        }
      }

      &:nth-of-type(even) {
        background-color: map-get($colors, 'red');

        .icon {
          background-color: #fff;
        }

        h4,
        p {
          color: #fff;
        }
      }

      .icon {
        @include align-center;
        justify-content: center;

        width: 80px;
        height: 80px;

        background-color: map-get($colors, 'red');
        border-radius: 50%;
      }

      h4 {
        margin: 15px 0 0;
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;

        padding: 0 50px;

        color: map-get($colors, 'darkgrey');

        @media screen and (max-width: $tablet-sm) {
          max-width: 350px;
        }
      }

      p {
        margin: 15px 0 0;

        font-family: 'Inter';
        font-size: 16px;
        line-height: 150%;
        text-align: center;

        color: map-get($colors, 'text');
      }
    }
  }
}
