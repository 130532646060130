@import '../../styles/helpers/index.scss';

.services {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__content {
    @include align-center;
    gap: 15px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }

      &:last-of-type {
        margin: 60px 0 0;

        @media screen and (max-width: $tablet-sm) {
          margin: 0;
        }
      }
    }

    p {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: map-get($colors, 'text');

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }

  &__list {
    margin: 65px 0 0;
    @include grid($columns: repeat(4, 1fr));

    &--item {
      position: relative;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      color: #070707;
      padding: 50px 0 40px;
      text-align: center;

      @include scale;

      &::before {
        content: '';

        background-image: url('../../static/arrow-link.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        width: 30px;
        height: 30px;

        position: absolute;
        top: 15px;
        right: 15px;
      }

      .icon {
        margin: 0 auto;
        background-color: map-get($colors, 'red');
        border-radius: 50%;
        width: 100px;
        height: 100px;

        @include align-center;
        justify-content: center;

        position: relative;

        &::before {
          content: '';

          position: absolute;

          bottom: -15px;

          height: 1px;
          width: 100%;
          background-color: map-get($colors, 'red');
        }
      }

      h4 {
        margin: 30px 0 0;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 24px;
        line-height: 100%;
      }
    }
  }
}
