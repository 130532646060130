@import '../../styles/helpers/index.scss';

.page-footer {
  background-color: map-get($colors, 'red');
  font-family: 'Montserrat', sans-serif;
  padding: 45px 0 30px;

  .container {
    @include align-center;
    flex-direction: column;

    & > a {
      &:not(:first-of-type) {
        color: #fff;
        position: relative;

        &::before {
          content: '';
          width: 0;
          height: 2px;
          background-color: #fff;

          transition: width 0.3s ease;

          position: absolute;
          bottom: 0;
        }

        &:hover {
          &::before {
            width: 100%;
          }
        }
      }
    }

    & > p {
      margin: 30px 0 0;
      color: #fff;
    }
  }

  &__logo {
    display: block;
    height: 100px;
    @include scale;

    img {
      object-fit: cover;
      height: inherit;
      width: auto;
    }
  }

  .phone {
    margin: 35px 0 0;

    font-size: 40px;
    font-weight: 700;
  }

  .email {
    margin: 35px 0 0;
    font-size: 24px;

    @media screen and (max-width: $tablet-sm) {
      font-size: 18px;
    }
  }

  .address {
    margin: 35px 0 0;
    font-size: 24px;
    color: #fff;
    text-align: center;

    @media screen and (max-width: $tablet-sm) {
      font-size: 16px;
    }
  }

  &__underline {
    margin: 75px 0 0;
    width: 100%;
    border-top: 1px solid #fff;
    padding: 25px 0 0;
    text-align: center;
    color: #fff;
    font-size: 12px;

    @media screen and (max-width: $tablet-sm) {
      padding-bottom: 60px;
    }

    & > a {
      display: block;
      color: #fff;
      margin: 15px 0 0;

      strong {
        font-weight: bold;
      }

      &:hover {
        strong {
          text-decoration: underline;
        }
      }
    }
  }

  .legal-links {
    display: flex;
    gap: 15px;
    justify-content: center;
    margin: 0 0 15px;

    a {
      color: #fff;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}
