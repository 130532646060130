@import '../../styles/helpers/index.scss';

.call-us {
  margin: 120px 0 -2px;
  padding: 90px 0 40px;

  background-image: url('../../static/call-us-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 -2px;
    background-position: left;
  }

  .section-title h2 {
    color: #fff !important;
  }

  p {
    margin: 20px 0 75px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    max-width: 600px;
    @media screen and (max-width: $tablet-sm) {
      font-size: 18px;
    }
  }

  &__link {
    @include align-center;
    gap: 35px;

    & > span {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      @media screen and (max-width: $tablet-sm) {
        font-size: 18px;
      }
    }
  }

  &__payments {
    margin: 10px 0 0;
    @include align-center;
    justify-content: flex-end;
    @media screen and (max-width: $tablet-sm) {
      margin: 45px 0 0;
      justify-content: space-between;
    }
    img {
      height: 50px;
      object-fit: cover;
      &:not(:last-of-type) {
        margin: 0 20px 0 0;
        @media screen and (max-width: $tablet-sm) {
          margin: 0;
        }
      }
    }
  }
}
