@import '../../styles/helpers/index.scss';

.steps {
  margin: -120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 0;
  }

  &__inner {
    padding: 60px 40px;
    @include space-between;
    align-items: center;
    gap: 15px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      padding: 40px 0;
    }
  }

  .container {
    background-color: map-get($colors, 'red');
    border-radius: 10px;

    .section-title h2 {
      color: #fff;

      .dashed::before {
        border-color: #ffb800 !important;
      }
    }

    .subtitle {
      margin: 15px 0 0;
      color: #fff;
      font-family: 'Inter';
      font-size: 24px;
      line-height: 32px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 18px;
        font-weight: 120%;
      }
    }
  }

  &__grid {
    @include grid($columns: repeat(3, 1fr));
    max-width: 70%;
    width: 100%;

    @media screen and (max-width: $tablet-sm) {
      margin: 15px 0 0;
      max-width: unset;
      width: auto;
    }

    &--item {
      @include scale;
      border-radius: 10px;
      padding: 30px 40px;
      background-color: #fff;
      text-align: center;

      img {
        margin: 0 auto 15px;
      }

      h5 {
        font-family: 'Inter';
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        color: map-get($colors, 'text');
      }
    }
  }
}
