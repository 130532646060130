@import '../../styles/helpers/index.scss';

.btn {
  padding: 15px 30px;

  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 18px;

  border-radius: 10px;

  &.contained {
    color: #fff;
    background-color: map-get($colors, 'red');
  }

  &.outlined {
    border: 2px solid map-get($colors, 'red');
    color: map-get($colors, 'red');
  }
}
