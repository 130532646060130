@import '../../styles/helpers/index.scss';

.areas {
  padding: 120px 0 240px;
  background-color: #f2f2f2;

  background-image: url('../../static/areas-bg.png');
  background-repeat: no-repeat;
  background-position: 22% 0;
  background-size: contain;

  @media screen and (max-width: $tablet-sm) {
    padding: 60px 0;
    background-image: none;
  }

  .container {
    @include space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  ul {
    margin: 45px 0 0;
    columns: 3;

    @media screen and (max-width: $tablet-sm) {
      columns: 1;
    }

    li {
      position: relative;
      text-indent: 20px;

      font-family: 'Inter';
      font-size: 18px;
      line-height: 200%;

      color: map-get($colors, 'text');

      &::before {
        content: '';
        background-image: url('../../static/arrow-list.svg');
        background-repeat: no-repeat;
        background-size: contain;

        width: 12px;
        height: 8px;

        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}
