@import '../../styles/helpers/index.scss';

.section-title {
  & > span {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;

    text-transform: uppercase;
    color: map-get($colors, 'red');
  }

  h2 {
    margin: 30px 0 0;

    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 60px;
    line-height: 130%;

    color: map-get($colors, 'darkgrey');

    @media screen and (max-width: $tablet-sm) {
      font-size: 42px;
    }
  }
}
