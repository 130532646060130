@import '../../styles/helpers/index.scss';

.page-header {
  padding: 25px 0 15px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: box-shadow 0.3s ease;

  border-bottom: 1px solid #bdbdbd;
  background-color: #fff;

  &.shadow {
    box-shadow: 0 5px 15px 0 rgba(#000, 0.5);
  }

  .container {
    @include align-center;
    justify-content: space-between;
  }

  &__logo {
    @media screen and (max-width: $tablet-sm) {
      max-width: 140px;
    }
  }

  .links {
    @include align-center;
    gap: 20px;

    a {
      &:first-of-type {
        @media screen and (max-width: $tablet-sm) {
          padding: 8px 10px;
          font-size: 16px;
        }
      }

      &:last-of-type {
        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }
    }
  }
}
