@import '../../styles/helpers/index.scss';

.hero {
  margin: 80px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 50px 0 0;
  }

  .container {
    display: flex;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      @media screen and (max-width: $tablet-sm) {
        flex-direction: column;

        max-width: 100% !important;
      }

      &:first-of-type {
        max-width: 40%;
      }

      &:last-of-type {
        max-width: 60%;
      }
    }
  }

  &__content {
    h1 {
      font-family: 'Montserrat';
      font-weight: 700;
      font-size: 86px;
      line-height: 120%;

      color: map-get($colors, 'darkgrey');

      @media screen and (max-width: $tablet-sm) {
        font-size: 60px;
      }
    }

    p {
      margin: 50px 0;
      font-family: 'Inter';
      font-weight: 400;
      font-size: 22px;
      line-height: 150%;
    }

    .links {
      @include align-center;
      gap: 25px;

      span {
        font-size: 20px;

        @media screen and (max-width: $tablet-sm) {
          font-size: 16px;
        }

        & > a {
          position: relative;
          color: map-get($colors, 'darkgrey');
          font-weight: bold;

          @media screen and (max-width: 391px) {
            font-weight: normal;
          }

          &::before {
            content: '';
            width: 0;
            height: 2px;
            background-color: map-get($colors, 'darkgrey');

            transition: width 0.3s ease;

            position: absolute;
            bottom: 0;
          }

          &:hover {
            &::before {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__image {
    width: 100%;
    background-image: url('../../static/hero-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media screen and (max-width: $tablet-sm) {
      min-height: 300px;
    }
  }
}
