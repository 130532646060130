@import '../../styles/helpers/index.scss';

.booking-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: $tablet-sm) {
    max-width: 100%;
    width: unset;
  }

  & *::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: map-get($colors, 'text');
  }

  & > * {
    &:not(input[type='submit']) {
      color: map-get($colors, 'text');
      padding: 0 20px;
      line-height: 50px;

      border-radius: 10px;
      border: 1px solid #bdbdbd;
      outline: none;

      font-size: 18px;
    }
  }

  input,
  select {
    display: block;
    max-width: 100%;

    height: 50px;
  }

  textarea {
    font-family: 'Montserrat', sans-serif;
    height: 120px !important;
    padding-top: 15px !important;
    line-height: 120% !important;
  }

  .btn {
    max-width: 120px;
    background-color: map-get($colors, 'red');
    border: none;
    color: #fff;
    border: none;

    &:hover {
      cursor: pointer;
      background-color: rgba(map-get($colors, 'red'), 0.8);
    }
  }
}

.data-protection-label {
  margin: 15px 0 0;
  @include align-center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
