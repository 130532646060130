@import '../../styles/helpers/index.scss';

.reviews {
  position: relative;
  margin: 120px 0 0;
  background-color: #f2f2f2;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .section-title {
    padding: 120px 0 0;

    @media screen and (max-width: $tablet-sm) {
      padding: 60px 0;
    }
  }

  &__list {
    margin: 60px 0 0;
    padding: 0 0 160px;

    &--card {
      margin: 0 15px;

      header {
        position: relative;
        padding: 45px 30px;
        background: #ffffff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        &::after {
          content: '';
          position: absolute;
          transition: all 0.3s ease-in;

          left: 10px;

          bottom: -50px;

          border-top: 30px solid #fff;
          border-bottom: 30px solid transparent;
          border-left: 30px solid transparent;
          border-right: 30px solid transparent;
        }

        .images {
          @include space-between;
          align-items: center;
        }

        p {
          margin: 45px 0 0;
          height: 270px;
          overflow: scroll;

          font-family: 'Inter';
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;

          color: map-get($colors, 'text');
        }
      }

      footer {
        margin: 30px 0 0;
        padding: 0 0 0 30px;

        p {
          font-family: 'Inter';
          font-style: normal;
          font-size: 18px;
          line-height: 120%;

          &:first-of-type {
            position: relative;
            font-weight: 600;
            margin: 0 0 5px;

            &::before {
              content: '';
              background-color: map-get($colors, 'red');

              position: absolute;
              left: -30px;
              top: 5px;

              width: 10px;
              height: 10px;

              border-radius: 50%;
            }
          }
        }
      }
    }

    @media screen and (max-width: $tablet-sm) {
      padding: 0 0 60px;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    bottom: 60px;

    @media screen and (max-width: $tablet-sm) {
      bottom: 15px;
    }

    li button:before {
      font-size: 12px;
      padding: 0 !important;
    }

    .slick-active {
      border: 1px solid map-get($colors, 'red');
      border-radius: 50%;

      button {
        &::before {
          font-size: 12px;
          color: map-get($colors, 'red');
        }
      }
    }
  }
}
