@import '../../styles/helpers/index.scss';

.about {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  &__content {
    margin: 35px 0 0;
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }
    }

    &--image {
      position: relative;

      &::before {
        content: '';

        background-image: url('../../static/pseudo-bg.svg');

        width: 105px;
        height: 260px;

        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }

      img {
        margin: 20px 0 0;
        width: 90%;
        z-index: 2;
        float: right;
        border-radius: 10px;

        @media screen and (max-width: $tablet-sm) {
          margin: 0 auto;
          float: unset;
          width: 100%;
        }
      }
    }

    h3 {
      margin: 0 0 30px;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 30px;
      line-height: 34px;
      color: #18181b;
    }

    p {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: map-get($colors, 'text');

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }
}
